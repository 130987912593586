<template>
  <v-dialog
    v-model="closeTask.dialog"
    persistent
    max-width="400"
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="headline">Close Task</v-card-title>
      <v-card-text>Are you sure you want to close this Task?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetClose">No, cancel</v-btn>
        <v-btn
          color="success"
          depressed
          :loading="closeTask.loading"
          @click="saveClose"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    fromTasking: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      closeTask: {
        dialog: false,
        task: {},
        loading: false,
      },
    };
  },

  methods: {
    openClose(task) {
      this.closeTask.task = task;
      this.closeTask.dialog = true;
    },

    resetClose() {
      this.closeTask.dialog = false;
      this.closeTask.task = {};
      this.closeTask.loading = false;
    },

    saveClose() {
      this.closeTask.loading = true;

      let dispach = "workload/closeWorkChunk";

      //this.fromTasking
      //   ? "tasking/recurringStore/closeWorkChunk"
      //  : "workload/closeWorkChunk";

      this.$store
        .dispatch(dispach, {
          appId: this.$route.params.id,
          chunkId: this.closeTask.task.id,
        })
        .then(() => {
          this.resetClose();
        })
        .catch(() => {
          this.closeTask.loading = false;
        });
    },
  },
};
</script>
